import React from 'react';

const OurVision = () => {
    const isPhone = window.innerWidth <500
    return isPhone? <div className={"d-flex flex-column justify-content-around gap-0 p-3 mt-5"} style={{
        backdropFilter: 'blur(10px)',
        border: '2px solid white',
        borderRadius: '10px',
    }}>
        <div className={"d-flex flex-column justify-content-center mb-2 text-start"}><p style={{fontSize:"18px", color:"#2D2147"}}>Our Vision</p></div>
        <div>
            <p style={{fontFamily:"Space Grotesk", color:"#2D2147",  lineHeight:"20px"}} className={"text-start"}>We are experts in health, decision making and technology, driven by theory
                and data. We create a no compromise agent for all patients, with the goal of ensuring dignity, support, and clarity for everyone.</p>
        </div>
    </div> : (
        <div className={"d-flex flex-row justify-content-around gap-3 p-3 mt-5"} style={{
            backdropFilter: 'blur(10px)',
            border: '2px solid white',
            borderRadius: '10px',
        }}>
            <div className={"d-flex flex-column justify-content-center"}><p style={{fontSize:"22px", color:"#2D2147"}}>Our Vision</p></div>
            <div>
                <p style={{fontFamily:"Space Grotesk", color:"#2D2147",fontSize:"18px",  lineHeight:"20px"}} className={"text-start"}>We are experts in health, decision making and technology, driven by theory
                    and data. We create a no compromise agent for all patients, with the goal of ensuring dignity, support, and clarity for everyone.</p>
            </div>
        </div>
    );
};

export default OurVision;