import React from 'react'
import mainLogo from '../img/mainLogo.svg'
import linkedIcon from '../img/LinkedIn.svg'
import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';

const Footer = () => {
    const webLinked = () => {
        logEvent(analytics, 'linkedin_epilog_button', { name: 'linkedin_epilog_button', purpose: 'perform_action' });
      };
    return (
        <div className='footerWrap'>
            <div class="container">
                <div class="mainLogo">
                    <img src={mainLogo} alt='' />
                </div>
                <ul>
                    <li>
                        <p class="comText">@2023 by EPILOG <span class="comInnerText">END OF LIFE COMPANION LTD</span></p>
                    </li>
                    <li>
                        <a href='https://www.linkedin.com/company/epilog-world/' target='_blank' onClick={webLinked}><img src={linkedIcon} alt='' /></a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
